<template>
	<div id='status'>
		<div class="row">
		<div class="col">
			<a id="77707008089" href="#" class="btn btn-circle default hoverable" style="border-left: 10px solid #35aa47;">
	        	<span class="normal">203 - Ernst</span>
	        	<span id="hover" class="hover">Geen lopend gesprek</span>
	        	<span id="status" class="status"></span>
	        </a>

	    	<a id="77707008090" href="#" class="btn btn-circle default hoverable" style="border-left: 10px solid #35aa47;">
	    		<span class="normal">204 - Mechteld</span>
	    		<span id="hover" class="hover">Geen lopend gesprek</span>
	    		<span id="status" class="status"></span>
	    	</a>

	        <a id="77707008094" href="#" class="btn btn-circle default hoverable" style="border-left: 10px solid #35aa47;">
	        	<span class="normal">208 - Leonie</span>
	        	<span id="hover" class="hover">Geen lopend gesprek</span>
	        	<span id="status" class="status"></span>
	        </a>

	        <a id="77707008092" href="#" class="btn btn-circle default hoverable" style="border-left: 10px solid #35aa47;">
	        	<span class="normal">206 - Jantiene</span>
	        	<span id="hover" class="hover">Geen lopend gesprek</span>
	        	<span id="status" class="status"></span>
	        </a>

	        <a id="77707008087" href="#" class="btn btn-circle default hoverable" style="border-left: 10px solid #35aa47;">
	        	<span class="normal">201 - Sven</span>
	        	<span id="hover" class="hover">Geen lopend gesprek</span>
	        	<span id="status" class="status"></span>
	        </a>

	        <a id="77707008142" href="#" class="btn btn-circle default hoverable" style="border-left: 10px solid #35aa47;">
	        	<span class="normal">209 - Jan-Willem</span>
	        	<span id="hover" class="hover">Geen lopend gesprek</span>
	        	<span id="status" class="status"></span>
	        </a>

	        <a id="77707008144" href="#" class="btn btn-circle default hoverable" style="border-left: 10px solid #35aa47;">
	        	<span class="normal">211 - Grytsje Anna</span>
	        	<span id="hover" class="hover">Geen lopend gesprek</span>
	        	<span id="status" class="status"></span>
	        </a>  
		</div>

		<div class="col">
			<a id="77707008091" href="#" class="btn btn-circle default hoverable" style="border-left: 10px solid #35aa47;">
	        	<span class="normal">205 - Coby</span>
	        	<span id="hover" class="hover">Geen lopend gesprek</span>
	        	<span id="status" class="status"></span>
	        </a>

	        <a id="77707008088" href="#" class="btn btn-circle default hoverable" style="border-left: 10px solid #35aa47;">
	        	<span class="normal">202 - Tonny</span>
	        	<span id="hover" class="hover">Geen lopend gesprek</span>
	        	<span id="status" class="status"></span>
	        </a>

	        <a id="77707008145" href="#" class="btn btn-circle default hoverable" style="border-left: 10px solid #35aa47;">
	        	<span class="normal">212 - Peter</span>
	        	<span id="hover" class="hover">Geen lopend gesprek</span>
	        	<span id="status" class="status"></span>
	        </a>

	        <a id="77705500333" href="#" class="btn btn-circle default hoverable" style="border-left: 10px solid #35aa47;">
	        	<span class="normal">000 - Joey</span>
	        	<span id="hover" class="hover">Geen lopend gesprek</span>
	        	<span id="status" class="status"></span>
	        </a>

	        <a id="77705500334" href="#" class="btn btn-circle default hoverable" style="border-left: 10px solid #35aa47;">
	        	<span class="normal">007 - Henk</span>
	        	<span id="hover" class="hover">Geen lopend gesprek</span>
	        	<span id="status" class="status"></span>
	        </a>

	        <a id="77705500348" href="#" class="btn btn-circle default hoverable" style="border-left: 10px solid #35aa47;">
	        	<span class="normal">300 - Nando</span>
	        	<span id="hover" class="hover">Geen lopend gesprek</span>
	        	<span id="status" class="status"></span>
	        </a>  
		</div>
		</div>
    </div>
</template>

<script>
  export default {
    name: 'Telephone',
    created() {
    	var socket = io.connect('https://ha.as61349.net/');
		  
		socket.emit('busywall', '1229d77c-5b0c-47f7-9497-6d6a958dff3d');
		  
		socket.on('reconnect', function () {
			socket.emit('busywall', '1229d77c-5b0c-47f7-9497-6d6a958dff3d');
		  });
          
        socket.on('dnd', function(new_info){
            if(new_info.dnd=='y')
			{
                $('#'+new_info.account_id).css('border-left', '10px solid #839BFF');
                $('#'+new_info.account_id+' span#status').text('dnd');
            }
            else
            {
                $('#'+new_info.account_id).css('border-left', '10px solid #35aa47');
                $('#'+new_info.account_id+' span#status').text('');
            }
        });
		  
		socket.on('early', function(new_info){
			$('#debug').append($('<li>').text(new_info.account_id + ' changed to ringing'));
			$('#'+new_info.account_id).css('border-left', '10px solid #ff9933');
			
			if(new_info.new_direction=='recipient')
			{
				$('#'+new_info.account_id+' span#status').text('in');
				$('#'+new_info.account_id+' span#hover').text(new_info.new_remote);
			}
			else
			{
				$('#'+new_info.account_id+' span#status').text('out');
				$('#'+new_info.account_id+' span#hover').text(new_info.new_remote);
			}		
		});
		  
	 	socket.on('confirmed', function(new_info){
			$('#debug').append($('<li>').text(new_info.account_id + ' changed to answer'));
			$('#'+new_info.account_id).css('border-left', '10px solid #d84a38');
			if(new_info.new_direction=='recipient')
			{
				$('#'+new_info.account_id+' span#status').text('in');
				$('#'+new_info.account_id+' span#hover').text(new_info.new_remote);
			}
			else
			{
				$('#'+new_info.account_id+' span#status').text('out');
				$('#'+new_info.account_id+' span#hover').text(new_info.new_remote);
			}
		});
		  
		socket.on('terminated', function(new_info){
			$('#debug').append($('<li>').text(new_info.account_id + ' changed to hangup of cancel'));
			$('#'+new_info.account_id).css('border-left', '10px solid #35aa47');
			$('#'+new_info.account_id+' span#status').text('');
			$('#'+new_info.account_id+' span#hover').text('Geen lopend gesprek');
		});
    },
    methods: {
    	
    },
    data () {
      return {
      	
      }
  	},
  };
</script>