<template>
	<ul class="list-group">
		<li class="list-group-item"><span class="h4">Node 1</span><span>
		<div class="box" v-if="node1 == 1">
		  <div class="server">
		    <ul>
		      <li></li>
		      <li></li>
		      <li></li>
		    </ul>
		  </div>
		</div>
		<div class="box" v-if="node1 == 2">
		  <div class="server warning">
		    <ul>
		      <li></li>
		      <li></li>
		      <li></li>
		    </ul>
		  </div>
		</div>
		<div class="box" v-if="node1 == 3">
		  <div class="server error">
		    <ul>
		      <li></li>
		      <li></li>
		      <li></li>
		    </ul>
		  </div>
		</div></span>
		</li>
		<li class="list-group-item"><span class="h4">Node 2</span><span>
		<div class="box" v-if="node2 == 1">
		  <div class="server">
		    <ul>
		      <li></li>
		      <li></li>
		      <li></li>
		    </ul>
		  </div>
		</div>
		<div class="box" v-if="node2 == 2">
		  <div class="server warning">
		    <ul>
		      <li></li>
		      <li></li>
		      <li></li>
		    </ul>
		  </div>
		</div>
		<div class="box" v-if="node2 == 3">
		  <div class="server error">
		    <ul>
		      <li></li>
		      <li></li>
		      <li></li>
		    </ul>
		  </div>
		</div></span>
		</li>
		<li class="list-group-item"><span class="h4">Node 3</span><span>
		<div class="box" v-if="node3 == 1">
		  <div class="server">
		    <ul>
		      <li></li>
		      <li></li>
		      <li></li>
		    </ul>
		  </div>
		</div>
		<div class="box" v-if="node3 == 2">
		  <div class="server warning">
		    <ul>
		      <li></li>
		      <li></li>
		      <li></li>
		    </ul>
		  </div>
		</div>
		<div class="box" v-if="node3 == 3">
		  <div class="server error">
		    <ul>
		      <li></li>
		      <li></li>
		      <li></li>
		    </ul>
		  </div>
		</div></span>
		</li>
	</ul>
</template>

<script>
  export default {
    name: 'Server',
    created() {
    	this.node1 = this.checkStatus(this.nodes[2][1])
    	this.node2 = this.checkStatus(this.nodes[1][1])
    	this.node3 = this.checkStatus(this.nodes[0][1])
    },
    methods: {
    	checkStatus(online) {
      		if(online) {
    			return 1
    		} else {
    			return 2
    		}
    	}
    },
    data () {
      return {
      	node1: 3,
      	node2: 3,
      	node3: 3
      }
  	},
  	props: ["nodes"]
  };
</script>