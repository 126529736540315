<template>
	<div class="container">
		<div class="row" v-for="expectation in expectations">
			<div class="col">
				<i v-if="expectation" class="fa fa-check text-danger float-left d-inline-block mr-2 mt-2"></i>
				<span class="d-inline-block">{{expectation[0]}}</span><span class="d-inline-block ml-2"> {{expectation[1]}}</span>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<a href="/expectations" class="btn btn-primary btn-block mt-5">Bekijk meldingen</a>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
    name: 'Expectations',
  	props: ["expectations"]
  };
</script>