<template>
	<div class="container-fluid dashboard">
		<center class="mt-0"><h1>Dashboard</h1></center>
		<div class="row dashboard-row mt-2">
			<div class="col-3">
				<div class="card p-3">
					<center class="mt-0"><h5 class="font-weight-bold">Verwachtingen</h5></center>
					<Expectations :expectations="expectations" />
				</div>	
			</div>
			<div class="col-6">
				<div class="card p-3">
					<center class="mt-0"><h5 class="font-weight-bold">Planning</h5></center>
					<iframe src='https://eskesmedia.atlassian.net/secure/PortfolioSharedReportView.jspa?r=SgWT9&t&e' width='100%' height='640' style='border : none;'></iframe>
				</div>
			</div>
			<div class="col-3">
				<div class="card p-3">
					<center class="mt-0"><h5 class="font-weight-bold">Telefoon</h5></center>
					<Telephone></Telephone>
				</div>
			</div>
		</div>
		<div class="row dashboard-row mt-2">
			<div class="col-3">
				<div class="card p-3">
					<center class="mt-0"><h5 class="font-weight-bold">Servers</h5></center>
					<Server :nodes="nodes"></Server>
				</div>
			</div>
			<div class="col-3">
				<div class="card p-3">
					<center class="mt-0"><h5 class="font-weight-bold">DWT Checks</h5></center>
					<Check :checks="checks" />
				</div>
			</div>
			<div class="col-6">
				<div class="card p-3">
					<center class="mt-0"><h5 class="font-weight-bold">X</h5></center>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import Server from './Server';
  import Telephone from './Telephone';
  import Check from './Check';
  import Expectations from './Expectations';

  export default {
    name: 'Dashboard',
    components: {
      Server,
      Telephone,
      Check,
      Expectations
    },
    props: ["nodes", "checks", "expectations"]
  };
</script>