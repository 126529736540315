// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import store from '../store/index'
import axios from 'axios'
import VueApexCharts from 'vue-apexcharts'
import 'bootstrap'

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
window.store = store

import Dashboard from '../components/Dashboard/Dashboard.vue'
Vue.component('dashboard', Dashboard)

import Status from '../components/Status.vue'
Vue.component('status', Status)
Vue.component('apexchart', VueApexCharts)

window.addEventListener("load", function(){
  if (document.getElementById("app").dataset.useVue == 'true') {
    const app = new Vue({
      el: '[data-behaviour="vue"]',
    })
  }
});