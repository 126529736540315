var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"list-group"},[_c('li',{staticClass:"list-group-item"},[_c('span',{staticClass:"h4"},[_vm._v("Node 1")]),_c('span',[(_vm.node1 == 1)?_c('div',{staticClass:"box"},[_vm._m(0)]):_vm._e(),_vm._v(" "),(_vm.node1 == 2)?_c('div',{staticClass:"box"},[_vm._m(1)]):_vm._e(),_vm._v(" "),(_vm.node1 == 3)?_c('div',{staticClass:"box"},[_vm._m(2)]):_vm._e()])]),_vm._v(" "),_c('li',{staticClass:"list-group-item"},[_c('span',{staticClass:"h4"},[_vm._v("Node 2")]),_c('span',[(_vm.node2 == 1)?_c('div',{staticClass:"box"},[_vm._m(3)]):_vm._e(),_vm._v(" "),(_vm.node2 == 2)?_c('div',{staticClass:"box"},[_vm._m(4)]):_vm._e(),_vm._v(" "),(_vm.node2 == 3)?_c('div',{staticClass:"box"},[_vm._m(5)]):_vm._e()])]),_vm._v(" "),_c('li',{staticClass:"list-group-item"},[_c('span',{staticClass:"h4"},[_vm._v("Node 3")]),_c('span',[(_vm.node3 == 1)?_c('div',{staticClass:"box"},[_vm._m(6)]):_vm._e(),_vm._v(" "),(_vm.node3 == 2)?_c('div',{staticClass:"box"},[_vm._m(7)]):_vm._e(),_vm._v(" "),(_vm.node3 == 3)?_c('div',{staticClass:"box"},[_vm._m(8)]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"server"},[_c('ul',[_c('li'),_vm._v(" "),_c('li'),_vm._v(" "),_c('li')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"server warning"},[_c('ul',[_c('li'),_vm._v(" "),_c('li'),_vm._v(" "),_c('li')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"server error"},[_c('ul',[_c('li'),_vm._v(" "),_c('li'),_vm._v(" "),_c('li')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"server"},[_c('ul',[_c('li'),_vm._v(" "),_c('li'),_vm._v(" "),_c('li')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"server warning"},[_c('ul',[_c('li'),_vm._v(" "),_c('li'),_vm._v(" "),_c('li')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"server error"},[_c('ul',[_c('li'),_vm._v(" "),_c('li'),_vm._v(" "),_c('li')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"server"},[_c('ul',[_c('li'),_vm._v(" "),_c('li'),_vm._v(" "),_c('li')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"server warning"},[_c('ul',[_c('li'),_vm._v(" "),_c('li'),_vm._v(" "),_c('li')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"server error"},[_c('ul',[_c('li'),_vm._v(" "),_c('li'),_vm._v(" "),_c('li')])])
}]

export { render, staticRenderFns }