<template>
	<div id="chart">
		<h2>Response time</h2>
      <apexchart type=line height=350 :options="chartOptions" :series="series" />
    </div>
</template>


<script>
export default {
    name: 'Status',
    props: ["id", "response_time"],
    data () {
      return {
        series: [{
            name: "Desktops",
            data: this.response_time
        }],
        chartOptions: {
          chart: {
                height: 350,
                zoom: {
                    enabled: true
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            title: {
                text: 'Response time afgelopen 24 uur.',
                align: 'left'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: this.id,
                labels: {
            		show: false,
            	}
            }
        }
    }
    }
    };
</script>