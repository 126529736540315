<template>
	<div class="container">
		<div class="row">
			<div class="col text-center">
				<h5>Komende 7 dagen</h5>
				<i v-if="checks[0]" class="fa fa-check text-success"></i>
				<i v-else class="fa fa-check text-danger"></i>
			</div>
			<div class="col text-center">
				<h5>Komende 30 dagen</h5>
				<i v-if="checks[1]" class="fa fa-check text-success"></i>
				<i v-else class="fa fa-check text-danger"></i>
			</div>
		</div>
		<div class="row">
			<div class="col text-center">
				<h5>Komende 90 dagen</h5>
				<i v-if="checks[2]" class="fa fa-check text-success"></i>
				<i v-else class="fa fa-check text-danger"></i>
			</div>
			<div class="col text-center">
				<h5>Komende 365 dagen</h5>
				<i v-if="checks[3]" class="fa fa-check text-success"></i>
				<i v-else class="fa fa-check text-danger"></i>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<a href="/checks" class="btn btn-primary btn-block mt-5">Bekijk meldingen</a>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
    name: 'Check',
  	props: ["checks"]
  };
</script>